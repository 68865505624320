/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const Heebo_100Thin = require('./Heebo_100Thin.ttf');
export const Heebo_200ExtraLight = require('./Heebo_200ExtraLight.ttf');
export const Heebo_300Light = require('./Heebo_300Light.ttf');
export const Heebo_400Regular = require('./Heebo_400Regular.ttf');
export const Heebo_500Medium = require('./Heebo_500Medium.ttf');
export const Heebo_600SemiBold = require('./Heebo_600SemiBold.ttf');
export const Heebo_700Bold = require('./Heebo_700Bold.ttf');
export const Heebo_800ExtraBold = require('./Heebo_800ExtraBold.ttf');
export const Heebo_900Black = require('./Heebo_900Black.ttf');
